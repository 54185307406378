interface LabelSet {
  [languageCode: string]: string;
}

interface LabelsInterface {
  redeemForPointsLabels: LabelSet;
  appExclusiveDealLabels: LabelSet;
  mcDeliveryOnlyLabels: LabelSet;
  addToMobileOrderLabels: LabelSet;
  addToMcDeliveryOrderLabels: LabelSet;
  viewEligibleItemsLabels: LabelSet;
  viewTermsOfOfferLabels: LabelSet;
  participatingRestaurantsLabels: LabelSet;
}
const enum LanguageString {
  Fulah = 'Fulah (Latin)',
  Arabic = 'Arabic',
  English = 'English',
  French = 'French',
  German = 'German',
  Spanish = 'Spanish',
  ChineseTraditional = 'Chinese (Traditional)',
  HausaLatin = 'Hausa (Latin)',
  PseudoLanguage = 'Pseudo Language',
  SamiNorthern = 'Sami (Northern)',
  SerbianCyrillic = 'Serbian (Cyrillic)',
  SerbianLatin = 'Serbian (Latin)',
}

export const LanguageRecord: Record<string, string> = {
  'aa-DJ': 'Afar',
  'aa-ER': 'Afar',
  'aa-ET': 'Afar',
  'af-NA': 'Afrikaans',
  'af-ZA': 'Afrikaans',
  'agq-CM': 'Aghem',
  'ak-GH': 'Akan',
  'sq-AL': 'Albanian',
  'sq-MK': 'Albanian',
  'gsw-CH': 'Alsatian',
  'gsw-FR': 'Alsatian',
  'gsw-LI': 'Alsatian',
  'am-ET': 'Amharic',
  'ar-001': LanguageString.Arabic,
  'ar-AE': LanguageString.Arabic,
  'ar-BH': LanguageString.Arabic,
  'ar-DJ': LanguageString.Arabic,
  'ar-DZ': LanguageString.Arabic,
  'ar-EG': LanguageString.Arabic,
  'ar-ER': LanguageString.Arabic,
  'ar-IL': LanguageString.Arabic,
  'ar-IQ': LanguageString.Arabic,
  'ar-JO': LanguageString.Arabic,
  'ar-KM': LanguageString.Arabic,
  'ar-KW': LanguageString.Arabic,
  'ar-LB': LanguageString.Arabic,
  'ar-LY': LanguageString.Arabic,
  'ar-MA': LanguageString.Arabic,
  'ar-MR': LanguageString.Arabic,
  'ar-OM': LanguageString.Arabic,
  'ar-PS': LanguageString.Arabic,
  'ar-QA': LanguageString.Arabic,
  'ar-SA': LanguageString.Arabic,
  'ar-SD': LanguageString.Arabic,
  'ar-SO': LanguageString.Arabic,
  'ar-SS': LanguageString.Arabic,
  'ar-SY': LanguageString.Arabic,
  'ar-TD': LanguageString.Arabic,
  'ar-TN': LanguageString.Arabic,
  'ar-YE': LanguageString.Arabic,
  'hy-AM': 'Armenian',
  'as-IN': 'Assamese',
  'ast-ES': 'Asturian',
  'asa-TZ': 'Asu',
  'az-Cyrl-AZ': 'Azerbaijani (Cyrillic)',
  'az-Latn-AZ': 'Azerbaijani (Latin)',
  'ksf-CM': 'Bafia',
  'bm-Latn-ML': 'Bamanankan (Latin)',
  'bn-BD': 'Bangla',
  'bn-IN': 'Bangla',
  'bas-CM': 'Basaa',
  'ba-RU': 'Bashkir',
  'eu-ES': 'Basque',
  'be-BY': 'Belarusian',
  'bem-ZM': 'Bemba',
  'bez-TZ': 'Bena',
  'byn-ER': 'Blin',
  'brx-IN': 'Bodo',
  'bs-Cyrl-BA': 'Bosnian (Cyrillic)',
  'bs-Latn-BA': 'Bosnian (Latin)',
  'br-FR': 'Breton',
  'bg-BG': 'Bulgarian',
  'my-MM': 'Burmese',
  'ca-AD': 'Catalan',
  'ca-ES': 'Catalan',
  'ca-FR': 'Catalan',
  'ca-IT': 'Catalan',
  'ceb-Latn-PH': 'Cebuan (Latin)',
  'tzm-Latn-': 'Central Atlas Tamazight (Latin)',
  'ku-Arab-IQ': 'Central Kurdish',
  'ccp-Cakm': 'Chakma',
  'ccp-Cakm-': 'Chakma',
  'cd-RU': 'Chechen',
  'chr-Cher-US': 'Cherokee',
  'cgg-UG': 'Chiga',
  'zh-CN': 'Chinese (Simplified)',
  'zh-SG': 'Chinese (Simplified)',
  'zh-HK': LanguageString.ChineseTraditional,
  'zh-MO': LanguageString.ChineseTraditional,
  'zh-TW': LanguageString.ChineseTraditional,
  'cu-RU': 'Church Slavic',
  'swc-CD': 'Congo Swahili',
  'kw-GB': 'Cornish',
  'co-FR': 'Corsican',
  'hr-HR': 'Croatian',
  'hr-BA': 'Croatian (Latin)',
  'cs-CZ': 'Czech',
  'da-DK': 'Danish',
  'da-GL': 'Danish',
  'prs-AF': 'Dari',
  'dv-MV': 'Divehi',
  'dua-CM': 'Duala',
  'nl-AW': 'Dutch',
  'nl-BE': 'Dutch',
  'nl-BQ': 'Dutch',
  'nl-CW': 'Dutch',
  'nl-NL': 'Dutch',
  'nl-SR': 'Dutch',
  'nl-SX': 'Dutch',
  'dz-BT': 'Dzongkha',
  'ebu-KE': 'Embu',
  'en-001': LanguageString.English,
  'en-029': LanguageString.English,
  'en-150': LanguageString.English,
  'en-AE': LanguageString.English,
  'en-AG': LanguageString.English,
  'en-AI': LanguageString.English,
  'en-AS': LanguageString.English,
  'en-AT': LanguageString.English,
  'en-AU': LanguageString.English,
  'en-BB': LanguageString.English,
  'en-BE': LanguageString.English,
  'en-BI': LanguageString.English,
  'en-BM': LanguageString.English,
  'en-BS': LanguageString.English,
  'en-BW': LanguageString.English,
  'en-BZ': LanguageString.English,
  'en-CA': LanguageString.English,
  'en-CC': LanguageString.English,
  'en-CH': LanguageString.English,
  'en-CK': LanguageString.English,
  'en-CM': LanguageString.English,
  'en-CX': LanguageString.English,
  'en-CY': LanguageString.English,
  'en-DE': LanguageString.English,
  'en-DK': LanguageString.English,
  'en-DM': LanguageString.English,
  'en-ER': LanguageString.English,
  'en-FI': LanguageString.English,
  'en-FJ': LanguageString.English,
  'en-FK': LanguageString.English,
  'en-FM': LanguageString.English,
  'en-GB': LanguageString.English,
  'en-GD': LanguageString.English,
  'en-GG': LanguageString.English,
  'en-GH': LanguageString.English,
  'en-GI': LanguageString.English,
  'en-GM': LanguageString.English,
  'en-GU': LanguageString.English,
  'en-GY': LanguageString.English,
  'en-HK': LanguageString.English,
  'en-IE': LanguageString.English,
  'en-IL': LanguageString.English,
  'en-IM': LanguageString.English,
  'en-IN': LanguageString.English,
  'en-IO': LanguageString.English,
  'en-JE': LanguageString.English,
  'en-JM': LanguageString.English,
  'en-KE': LanguageString.English,
  'en-KI': LanguageString.English,
  'en-KN': LanguageString.English,
  'en-KY': LanguageString.English,
  'en-LC': LanguageString.English,
  'en-LR': LanguageString.English,
  'en-LS': LanguageString.English,
  'en-MG': LanguageString.English,
  'en-MH': LanguageString.English,
  'en-MO': LanguageString.English,
  'en-MP': LanguageString.English,
  'en-MS': LanguageString.English,
  'en-MT': LanguageString.English,
  'en-MU': LanguageString.English,
  'en-MW': LanguageString.English,
  'en-MY': LanguageString.English,
  'en-NA': LanguageString.English,
  'en-NF': LanguageString.English,
  'en-NG': LanguageString.English,
  'en-NL': LanguageString.English,
  'en-NR': LanguageString.English,
  'en-NU': LanguageString.English,
  'en-NZ': LanguageString.English,
  'en-PG': LanguageString.English,
  'en-PH': LanguageString.English,
  'en-PK': LanguageString.English,
  'en-PN': LanguageString.English,
  'en-PR': LanguageString.English,
  'en-PW': LanguageString.English,
  'en-RW': LanguageString.English,
  'en-SB': LanguageString.English,
  'en-SC': LanguageString.English,
  'en-SD': LanguageString.English,
  'en-SE': LanguageString.English,
  'en-SG': LanguageString.English,
  'en-SH': LanguageString.English,
  'en-SI': LanguageString.English,
  'en-SL': LanguageString.English,
  'en-SS': LanguageString.English,
  'en-SX': LanguageString.English,
  'en-SZ': LanguageString.English,
  'en-TC': LanguageString.English,
  'en-TK': LanguageString.English,
  'en-TO': LanguageString.English,
  'en-TT': LanguageString.English,
  'en-TV': LanguageString.English,
  'en-TZ': LanguageString.English,
  'en-UG': LanguageString.English,
  'en-UM': LanguageString.English,
  'en-US': LanguageString.English,
  'en-VC': LanguageString.English,
  'en-VG': LanguageString.English,
  'en-VI': LanguageString.English,
  'en-VU': LanguageString.English,
  'en-WS': LanguageString.English,
  'en-ZA': LanguageString.English,
  'en-ZM': LanguageString.English,
  'en-ZW': LanguageString.English,
  'eo-001': 'Esperanto',
  'et-EE': 'Estonian',
  'ee-GH': 'Ewe',
  'ee-TG': 'Ewe',
  'ewo-CM': 'Ewondo',
  'fo-DK': 'Faroese',
  'fo-FO': 'Faroese',
  'fil-PH': 'Filipino',
  'fi-FI': 'Finnish',
  'fr-BE': LanguageString.French,
  'fr-BF': LanguageString.French,
  'fr-BI': LanguageString.French,
  'fr-BJ': LanguageString.French,
  'fr-BL': LanguageString.French,
  'fr-CA': LanguageString.French,
  'fr-CD': LanguageString.French,
  'fr-CF': LanguageString.French,
  'fr-CG': LanguageString.French,
  'fr-CH': LanguageString.French,
  'fr-CI': LanguageString.French,
  'fr-CM': LanguageString.French,
  'fr-DJ': LanguageString.French,
  'fr-DZ': LanguageString.French,
  'fr-FR': LanguageString.French,
  'fr-GA': LanguageString.French,
  'fr-GF': LanguageString.French,
  'fr-GN': LanguageString.French,
  'fr-GP': LanguageString.French,
  'fr-GQ': LanguageString.French,
  'fr-HT': LanguageString.French,
  'fr-KM': LanguageString.French,
  'fr-LU': LanguageString.French,
  'fr-MA': LanguageString.French,
  'fr-MC': LanguageString.French,
  'fr-MF': LanguageString.French,
  'fr-MG': LanguageString.French,
  'fr-ML': LanguageString.French,
  'fr-MQ': LanguageString.French,
  'fr-MR': LanguageString.French,
  'fr-MU': LanguageString.French,
  'fr-NC': LanguageString.French,
  'fr-NE': LanguageString.French,
  'fr-PF': LanguageString.French,
  'fr-PM': LanguageString.French,
  'fr-RE': LanguageString.French,
  'fr-RW': LanguageString.French,
  'fr-SC': LanguageString.French,
  'fr-SN': LanguageString.French,
  'fr-SY': LanguageString.French,
  'fr-TD': LanguageString.French,
  'fr-TG': LanguageString.French,
  'fr-TN': LanguageString.French,
  'fr-VU': LanguageString.French,
  'fr-WF': LanguageString.French,
  'fr-YT': LanguageString.French,
  'fy-NL': 'Frisian',
  'fur-IT': 'Friulian',
  'ff-CM': 'Fulah',
  'ff-GN': 'Fulah',
  'ff-Latn-SN': 'Fulah',
  'ff-MR': 'Fulah',
  'ff-NG': 'Fulah',
  'ff-Latn-BF': LanguageString.Fulah,
  'ff-Latn-CM': LanguageString.Fulah,
  'ff-Latn-GH': LanguageString.Fulah,
  'ff-Latn-GM': LanguageString.Fulah,
  'ff-Latn-GN': LanguageString.Fulah,
  'ff-Latn-GW': LanguageString.Fulah,
  'ff-Latn-LR': LanguageString.Fulah,
  'ff-Latn-MR': LanguageString.Fulah,
  'ff-Latn-NE': LanguageString.Fulah,
  'ff-Latn-NG': LanguageString.Fulah,
  'ff-Latn-SL': LanguageString.Fulah,
  'gl-ES': 'Galician',
  'lg-UG': 'Ganda',
  'ka-GE': 'Georgian',
  'de-AT': LanguageString.German,
  'de-BE': LanguageString.German,
  'de-CH': LanguageString.German,
  'de-DE': LanguageString.German,
  'de-IT': LanguageString.German,
  'de-LI': LanguageString.German,
  'de-LU': LanguageString.German,
  'el-CY': 'Greek',
  'el-GR': 'Greek',
  'kl-GL': 'Greenlandic',
  'gn-PY': 'Guarani',
  'gu-IN': 'Gujarati',
  'guz-KE': 'Gusii',
  'ha-Latn-GH': LanguageString.HausaLatin,
  'ha-Latn-NE': LanguageString.HausaLatin,
  'ha-Latn-NG': LanguageString.HausaLatin,
  'haw-US': 'Hawaiian',
  'he-IL': 'Hebrew',
  'hi-IN': 'Hindi',
  'hu-HU': 'Hungarian',
  'is-IS': 'Icelandic',
  'ig-NG': 'Igbo',
  'id-ID': 'Indonesian',
  'ia-001': 'Interlingua',
  'ia-FR': 'Interlingua',
  'iu-Latn-CA': 'Inuktitut (Latin)',
  'iu-Cans-CA': 'Inuktitut (Syllabics)',
  'ga-IE': 'Irish',
  'it-CH': 'Italian',
  'it-IT': 'Italian',
  'it-SM': 'Italian',
  'it-VA': 'Italian',
  'ja-JP': 'Japanese',
  'jv-Latn': 'Javanese',
  'jv-Latn-ID': 'Javanese',
  'dyo-SN': 'Jola-Fonyi',
  'quc-Latn-GT': "K'iche",
  'kea-CV': 'Kabuverdianu',
  'kab-DZ': 'Kabyle',
  'kkj-CM': 'Kako',
  'kln-KE': 'Kalenjin',
  'kam-KE': 'Kamba',
  'kn-IN': 'Kannada',
  'ks-Arab': 'Kashmiri',
  'ks-Arab-IN': 'Kashmiri',
  'kk-KZ': 'Kazakh',
  'km-KH': 'Khmer',
  'ki-KE': 'Kikuyu',
  'rw-RW': 'Kinyarwanda',
  'sw-KE': 'Kiswahili',
  'sw-TZ': 'Kiswahili',
  'sw-UG': 'Kiswahili',
  'kok-IN': 'Konkani',
  'ko-KP': 'Korean',
  'ko-KR': 'Korean',
  'khq-ML': 'Koyra Chiini',
  'ses-ML': 'Koyraboro Senni',
  'ku-Arab-IR': 'Kurdish',
  'nmg-CM': 'Kwasio',
  'ky-KG': 'Kyrgyz',
  'lkt-US': 'Lakota',
  'lag-TZ': 'Langi',
  'lo-LA': 'Lao',
  'lv-LV': 'Latvian',
  'ln-AO': 'Lingala',
  'ln-CD': 'Lingala',
  'ln-CF': 'Lingala',
  'ln-CG': 'Lingala',
  'lt-LT': 'Lithuanian',
  'nds-DE': 'Low German',
  'nds-NL': 'Low German',
  'dsb-DE': 'Lower Sorbian',
  'lu-CD': 'Luba-Katanga',
  'luo-KE': 'Luo',
  'lb-LU': 'Luxembourgish',
  'luy-KE': 'Luyia',
  'mk-MK': 'Macedonian',
  'jmc-TZ': 'Machame',
  'mgh-MZ': 'Makhuwa-Meetto',
  'kde-TZ': 'Makonde',
  'mg-MG': 'Malagasy',
  'ms-BN': 'Malay',
  'ms-MY': 'Malay',
  'ml-IN': 'Malayalam',
  'mt-MT': 'Maltese',
  'gv-IM': 'Manx',
  'mi-NZ': 'Maori',
  'arn-CL': 'Mapudungun',
  'mr-IN': 'Marathi',
  'mas-KE': 'Masai',
  'mas-TZ': 'Masai',
  'mzn-IR': 'Mazanderani',
  'mer-KE': 'Meru',
  'mgo-CM': "Meta'",
  'moh-CA': 'Mohawk',
  'mn-MN': 'Mongolian (Cyrillic)',
  'mn-Mong-CN': 'Mongolian (Traditional Mongolian)',
  'mn-Mong-MN': 'Mongolian (Traditional Mongolian)',
  'mfe-MU': 'Morisyen',
  'mua-CM': 'Mundang',
  'nqo-GN': "N'ko",
  'naq-NA': 'Nama',
  'ne-IN': 'Nepali',
  'ne-NP': 'Nepali',
  'nnh-CM': 'Ngiemboon',
  'jgo-CM': 'Ngomba',
  'nd-ZW': 'North Ndebele',
  'lrc-IQ': 'Northern Luri',
  'lrc-IR': 'Northern Luri',
  'nb-NO': 'Norwegian (Bokmal)',
  'nn-NO': 'Norwegian (Nynorsk)',
  'nb-SJ': 'Norwegian Bokmål',
  'nus-SD': 'Nuer',
  'nus-SS': 'Nuer',
  'nyn-UG': 'Nyankole',
  'oc-FR': 'Occitan',
  'or-IN': 'Odia',
  'om-ET': 'Oromo',
  'om-KE': 'Oromo',
  'os-GE': 'Ossetian',
  'os-RU': 'Ossetian',
  'ps-AF': 'Pashto',
  'ps-PK': 'Pashto',
  'fa-AF': 'Persian',
  'fa-IR': 'Persian',
  'pl-PL': 'Polish',
  'pt-AO': 'Portuguese',
  'pt-BR': 'Portuguese',
  'pt-CH': 'Portuguese',
  'pt-CV': 'Portuguese',
  'pt-GQ': 'Portuguese',
  'pt-GW': 'Portuguese',
  'pt-LU': 'Portuguese',
  'pt-MO': 'Portuguese',
  'pt-MZ': 'Portuguese',
  'pt-PT': 'Portuguese',
  'pt-ST': 'Portuguese',
  'pt-TL': 'Portuguese',
  'qps-ploc': LanguageString.PseudoLanguage,
  'qps-ploca': LanguageString.PseudoLanguage,
  'qps-plocm': LanguageString.PseudoLanguage,
  'pa-Arab-PK': 'Punjabi',
  'pa-IN': 'Punjabi',
  'quz-BO': 'Quechua',
  'quz-EC': 'Quechua',
  'quz-PE': 'Quechua',
  'ksh-DE': 'Ripuarian',
  'ro-MD': 'Romanian',
  'ro-RO': 'Romanian',
  'rm-CH': 'Romansh',
  'rof-TZ': 'Rombo',
  'rn-BI': 'Rundi',
  'ru-BY': 'Russian',
  'ru-KG': 'Russian',
  'ru-KZ': 'Russian',
  'ru-MD': 'Russian',
  'ru-RU': 'Russian',
  'ru-UA': 'Russian',
  'rwk-TZ': 'Rwa',
  'ssy-ER': 'Saho',
  'sah-RU': 'Sakha',
  'saq-KE': 'Samburu',
  'smn-FI': 'Sami (Inari)',
  'smj-NO': 'Sami (Lule)',
  'smj-SE': 'Sami (Lule)',
  'se-FI': LanguageString.SamiNorthern,
  'se-NO': LanguageString.SamiNorthern,
  'se-SE': LanguageString.SamiNorthern,
  'sms-FI': 'Sami (Skolt)',
  'sma-NO': 'Sami (Southern)',
  'sma-SE': 'Sami (Southern)',
  'sg-CF': 'Sango',
  'sbp-TZ': 'Sangu',
  'sa-IN': 'Sanskrit',
  'gd-GB': 'Scottish Gaelic',
  'seh-MZ': 'Sena',
  'sr-Cyrl-BA': LanguageString.SerbianCyrillic,
  'sr-Cyrl-CS': LanguageString.SerbianCyrillic,
  'sr-Cyrl-ME': LanguageString.SerbianCyrillic,
  'sr-Cyrl-RS': LanguageString.SerbianCyrillic,
  'sr-Latn-BA': LanguageString.SerbianLatin,
  'sr-Latn-CS': LanguageString.SerbianLatin,
  'sr-Latn-ME': LanguageString.SerbianLatin,
  'sr-Latn-RS': LanguageString.SerbianLatin,
  'nso-ZA': 'Sesotho sa Leboa',
  'tn-BW': 'Setswana',
  'tn-ZA': 'Setswana',
  'ksb-TZ': 'Shambala',
  'sn-Latn': 'Shona',
  'sn-Latn-ZW': 'Shona',
  'sd-Arab-PK': 'Sindhi',
  'si-LK': 'Sinhala',
  'sk-SK': 'Slovak',
  'sl-SI': 'Slovenian',
  'xog-UG': 'Soga',
  'so-DJ': 'Somali',
  'so-ET': 'Somali',
  'so-KE': 'Somali',
  'so-SO': 'Somali',
  'st-ZA': 'Sotho',
  'nr-ZA': 'South Ndebele',
  'st-LS': 'Southern Sotho',
  'es-419': LanguageString.Spanish,
  'es-AR': LanguageString.Spanish,
  'es-BO': LanguageString.Spanish,
  'es-BR': LanguageString.Spanish,
  'es-BZ': LanguageString.Spanish,
  'es-CL': LanguageString.Spanish,
  'es-CO': LanguageString.Spanish,
  'es-CR': LanguageString.Spanish,
  'es-CU': LanguageString.Spanish,
  'es-DO': LanguageString.Spanish,
  'es-EC': LanguageString.Spanish,
  'es-ES': LanguageString.Spanish,
  'es-ES_tradnl': LanguageString.Spanish,
  'es-GQ': LanguageString.Spanish,
  'es-GT': LanguageString.Spanish,
  'es-HN': LanguageString.Spanish,
  'es-MX': LanguageString.Spanish,
  'es-NI': LanguageString.Spanish,
  'es-PA': LanguageString.Spanish,
  'es-PE': LanguageString.Spanish,
  'es-PH': LanguageString.Spanish,
  'es-PR': LanguageString.Spanish,
  'es-PY': LanguageString.Spanish,
  'es-SV': LanguageString.Spanish,
  'es-US': LanguageString.Spanish,
  'es-UY': LanguageString.Spanish,
  'es-VE': LanguageString.Spanish,
  'zgh-Tfng': 'Standard Moroccan Tamazight',
  'zgh-Tfng-MA': 'Standard Moroccan Tamazight',
  'ss-SZ': 'Swati',
  'ss-ZA': 'Swati',
  'sv-AX': 'Swedish',
  'sv-FI': 'Swedish',
  'sv-SE': 'Swedish',
  'syr-SY': 'Syriac',
  'shi-Tfng': 'Tachelhit',
  'shi-Tfng-MA': 'Tachelhit',
  'shi-Latn-MA': 'Tachelhit (Latin)',
  'dav-KE': 'Taita',
  'tg-Cyrl-TJ': 'Tajik (Cyrillic)',
  'tzm-Latn-DZ': 'Tamazight (Latin)',
  'ta-IN': 'Tamil',
  'ta-LK': 'Tamil',
  'ta-MY': 'Tamil',
  'ta-SG': 'Tamil',
  'twq-NE': 'Tasawaq',
  'tt-RU': 'Tatar',
  'te-IN': 'Telugu',
  'teo-KE': 'Teso',
  'teo-UG': 'Teso',
  'th-TH': 'Thai',
  'bo-CN': 'Tibetan',
  'bo-IN': 'Tibetan',
  'tig-ER': 'Tigre',
  'ti-ER': 'Tigrinya',
  'ti-ET': 'Tigrinya',
  'to-TO': 'Tongan',
  'ts-ZA': 'Tsonga',
  'tr-CY': 'Turkish',
  'tr-TR': 'Turkish',
  'tk-TM': 'Turkmen',
  'uk-UA': 'Ukrainian',
  'hsb-DE': 'Upper Sorbian',
  'ur-IN': 'Urdu',
  'ur-PK': 'Urdu',
  'ug-CN': 'Uyghur',
  'uz-Arab': 'Uzbek',
  'uz-Arab-AF': 'Uzbek',
  'uz-Cyrl-UZ': 'Uzbek (Cyrillic)',
  'uz-Latn-UZ': 'Uzbek (Latin)',
  'vai-Vaii-LR': 'Vai',
  'vai-Latn-LR': 'Vai (Latin)',
  'ca-ES-': 'Valencian',
  've-ZA': 'Venda',
  'vi-VN': 'Vietnamese',
  'vo-001': 'Volapük',
  'vun-TZ': 'Vunjo',
  'wae-CH': 'Walser',
  'cy-GB': 'Welsh',
  'wal-ET': 'Wolaytta',
  'wo-SN': 'Wolof',
  'xh-ZA': 'Xhosa',
  'yav-CM': 'Yangben',
  'ii-CN': 'Yi',
  'yo-BJ': 'Yoruba',
  'yo-NG': 'Yoruba',
  'dje-NE': 'Zarma',
  'zu-ZA': 'Zulu',
};

export const Labels: LabelsInterface = {
  redeemForPointsLabels: {
    'en-US': 'Redeem for XXXX pts',
    'es-US': 'Canjear por XXXX pts',
    'en-CA': 'Redeem for XXXX pts',
    'fr-CA': 'Échangez contre XXXX points',
    'en-GB': 'Redeem for XXXX pts',
    'de-DE': 'Einlösen für XXXX Punkte',
    'en-AU': 'Redeem for XXXX pts',
  },
  appExclusiveDealLabels: {
    'en-US': 'App Exclusive Deal',
    'es-US': 'Oferta exclusiva de la app',
    'en-CA': 'App Exclusive Deal',
    'fr-CA': 'Offre exclusive à l’application',
    'en-GB': 'App Exclusive Deal',
    'de-DE': 'App-exklusives Angebot',
    'en-AU': 'App Exclusive Deal',
  },
  mcDeliveryOnlyLabels: {
    'en-US': 'McDelivery Only',
    'es-US': 'Solo McDelivery',
    'en-CA': 'McDelivery Only',
    'fr-CA': 'Livraison Mc',
    'en-GB': 'McDelivery Only',
    'de-DE': 'Nur McDelivery',
    'en-AU': 'McDelivery Only',
  },
  addToMobileOrderLabels: {
    'en-US': 'Add to Mobile Order',
    'es-US': 'Añadir a pedido móvil',
    'en-CA': 'Add to Mobile Order',
    'fr-CA': 'Ajouter à la commande mobile',
    'en-GB': 'Add to Mobile Order',
    'de-DE': 'Zur mobilen Bestellung hinzufügen',
    'en-AU': 'Add to Mobile Order',
  },
  addToMcDeliveryOrderLabels: {
    'en-US': 'Add to McDelivery Order',
    'es-US': 'Añadir al pedido de McDelivery',
    'en-CA': 'Add to McDelivery Order',
    'fr-CA': 'Ajouter à la commande McDelivery',
    'en-GB': 'Add to McDelivery Order',
    'de-DE': 'Zur McDelivery-Bestellung hinzufügen',
    'en-AU': 'Add to McDelivery Order',
  },
  viewEligibleItemsLabels: {
    'en-US': 'View eligible items',
    'es-US': 'Ver artículos elegibles',
    'en-CA': 'View eligible items',
    'fr-CA': 'Voir les articles éligibles',
    'en-GB': 'View eligible items',
    'de-DE': 'Berechtigte Artikel anzeigen',
    'en-AU': 'View eligible items',
  },
  viewTermsOfOfferLabels: {
    'en-US': 'View terms of this offer',
    'es-US': 'Ver términos de esta oferta',
    'en-CA': 'View terms of this offer',
    'fr-CA': 'Voir les conditions de cette offre',
    'en-GB': 'View terms of this offer',
    'de-DE': 'Angebotsbedingungen anzeigen',
    'en-AU': 'View terms of this offer',
  },
  participatingRestaurantsLabels: {
    'en-US': 'Participating Restaurants',
    'es-US': 'Restaurantes participantes',
    'en-CA': 'Participating Restaurants',
    'fr-CA': 'Restaurants participants',
    'en-GB': 'Participating Restaurants',
    'de-DE': 'Teilnehmende Restaurants',
    'en-AU': 'Participating Restaurants',
  },
};

export const ExpiryLabels: Record<string, Record<string, string>> = {
  today: {
    'en-US': 'Expires Today',
    'es-US': 'Expira Hoy',
    'en-CA': 'Expires Today',
    'fr-CA': "Expire Aujourd'hui",
    'en-GB': 'Expires Today',
    'de-DE': 'Läuft heute ab',
    'en-AU': 'Expires Today',
  },
  tomorrow: {
    'en-US': 'Expires Tomorrow',
    'es-US': 'Expira Mañana',
    'en-CA': 'Expires Tomorrow',
    'fr-CA': 'Expire Demain',
    'en-GB': 'Expires Tomorrow',
    'de-DE': 'Läuft morgen ab',
    'en-AU': 'Expires Tomorrow',
  },
  inDays: {
    'en-US': 'Expires in {days} days',
    'es-US': 'Expira en {days} días',
    'en-CA': 'Expires in {days} days',
    'fr-CA': 'Expire dans {days} jours',
    'en-GB': 'Expires in {days} days',
    'de-DE': 'Läuft in {days} Tagen ab',
    'en-AU': 'Expires in {days} days',
  },
  fullDate: {
    'en-US': 'Expires {date}',
    'es-US': 'Expira el {date}',
    'en-CA': 'Expires {date}',
    'fr-CA': 'Expire le {date}',
    'en-GB': 'Expires {date}',
    'de-DE': 'Läuft am {date} ab',
    'en-AU': 'Expires {date}',
  },
  default: {
    'en-US': 'Expires on XX/XX/XXXX',
    'es-US': 'Expira el XX/XX/XXXX',
    'en-CA': 'Expires on XX/XX/XXXX',
    'fr-CA': 'Expire le XX/XX/XXXX',
    'en-GB': 'Expires on XX/XX/XXXX',
    'de-DE': 'Läuft am XX/XX/XXXX ab',
    'en-AU': 'Expires on XX/XX/XXXX',
  },
};
export const LanguageCodesSelectBoxValues = Object.keys(LanguageRecord).map((key) => ({
  id: key,
  name: key,
}));
const languageNames = Object.values(LanguageRecord);
export type Language = typeof languageNames[number];
