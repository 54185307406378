import styled from '@emotion/styled';
import { scalingFactorGMA } from 'components/shared/modal/gmaViewForm/GmaViewForm.const';
import { Icon } from 'components/shared/icon';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
export const Container = styled.div<{ secondaryLanguage?: string}>`
  height: ${1034 * scalingFactorGMA}px;
  width: ${680 * scalingFactorGMA}px;
  gap: ${42 * scalingFactorGMA}px;
  padding-top: ${({ secondaryLanguage }) => (secondaryLanguage ? 36 : 92 ) * scalingFactorGMA}px;
  padding-right: ${134 * scalingFactorGMA}px;
  padding-bottom: ${92 * scalingFactorGMA}px;
  padding-left: ${134 * scalingFactorGMA}px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Speedee;
`;

export const IphoneFrame = styled.div`
  width: ${400 * scalingFactorGMA}px;
  height: ${812 * scalingFactorGMA}px;
  border-radius: ${60 * scalingFactorGMA}px;
  border: ${12 * scalingFactorGMA}px solid #292929;
`;
export const InsideFrame = styled.div`
  width: ${400 * scalingFactorGMA}px;
  height: ${812 * scalingFactorGMA}px;
`;

export const DetailsContainer = styled.div`
  width: ${375 * scalingFactorGMA}px;
  max-height: ${712.33 * scalingFactorGMA}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${16 * scalingFactorGMA}px;
  gap: ${16 * scalingFactorGMA}px;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
    /* Hide scrollbar for Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;

  /* Hide scrollbar for IE/Edge */
  -ms-overflow-style: none;
`;

export const AboveTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  padding: 0px;
  width: ${375 * scalingFactorGMA}px;
  height: ${47 * scalingFactorGMA}px;
`;

export const StyledIcon = styled.div`
  width: ${20 * scalingFactorGMA}px;
  height: ${27 * scalingFactorGMA}px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: ${20.35 * scalingFactorGMA}px;
  margin-top: ${20.35 * scalingFactorGMA}px;
`;

export const Title = styled.div`
  width: ${279.3 * scalingFactorGMA}px;
  font-family: Speedee;
  font-weight: 700;
  font-size: ${27.14 * scalingFactorGMA}px;
  line-height: ${27.14 * scalingFactorGMA}px;
  letter-spacing: -0.13px;
`;

export const TitleContainer = styled.div`
  flex: 1 1 auto;
  width: ${343 * scalingFactorGMA}px;
  overflow-wrap: break-word;
`;

export const PointOfDistribution = styled.div`
  width: ${343 * scalingFactorGMA}px;
  height: ${20.35 * scalingFactorGMA}px;
  display: flex;
  align-items: center;
`;

export const PointOfDistributionText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: ${3.39 * scalingFactorGMA}px;
  height: ${14 * scalingFactorGMA}px;
  font-family: Speedee;
  font-weight: 700;
  font-size: ${11.87 * scalingFactorGMA}px;
  line-height: ${13.57 * scalingFactorGMA}px;
  letter-spacing: -0.13px;
`;

export const StyledAppDealImage = styled(Icon)`
  width: ${22.35 * scalingFactorGMA}px;
  height: ${22.35 * scalingFactorGMA}px;
  fill: ${({ theme }) => newGlobalTheme.colors.text.primary};
  margin-right: 2px;
`;

export const GeneralDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 8 2 auto;
  padding: 0px;
  gap: 5px;
  width: ${343 * scalingFactorGMA}px;
`;

export const Bottom = styled.div`
  width: ${343 * scalingFactorGMA}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: ${10.18 * scalingFactorGMA}px;
  flex: 2 1 auto;
`;

export const BottomSectionFirst = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: ${3.39 * scalingFactorGMA}px;
  width: ${343 * scalingFactorGMA}px;
  height: ${21 * scalingFactorGMA}px;
  font-family: Speedee;
  font-weight: 700;
  font-size: ${15.26 * scalingFactorGMA}px;
  line-height: ${20.35 * scalingFactorGMA}px;
  letter-spacing: -0.13px;
`;

export const BottomSectionSecond = styled.div`
  display: flex;
  width: ${343 * scalingFactorGMA}px;
  height: ${72 * scalingFactorGMA}px;
  gap: ${10 * scalingFactorGMA}px;
  padding-top: ${15 * scalingFactorGMA}px;
  padding-right: ${24 * scalingFactorGMA}px;
  padding-bottom: ${15 * scalingFactorGMA}px;
  padding-left: ${24 * scalingFactorGMA}px;
`;

export const BottomCommonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: ${24 * scalingFactorGMA}px;
  width: ${247 * scalingFactorGMA}px;
  height: ${48 * scalingFactorGMA}px;
`;

export const BottomCommonDivContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${12 * scalingFactorGMA}px ${16 * scalingFactorGMA}px ${12 * scalingFactorGMA}px ${24 * scalingFactorGMA}px;
  gap: ${24 * scalingFactorGMA}px;
  width: ${343 * scalingFactorGMA}px;
  height: ${72 * scalingFactorGMA}px;
  min-height: ${72 * scalingFactorGMA}px;
  background: #ffffff;
  border-radius: 4px;
  border: 0.5px solid #959595;
`;

export const StyledMcdImage = styled(Icon)`
  width: ${60 * scalingFactorGMA}px;
  height: ${60 * scalingFactorGMA}px;
  fill: ${({ theme }) => newGlobalTheme.colors.text.primary};
`;

export const StyledMcdPng = styled.img`
  width: ${50 * scalingFactorGMA}px;
  height: ${50 * scalingFactorGMA}px;
`;

export const StyledArrowImage = styled(Icon)`
  width: ${23 * scalingFactorGMA}px;
  height: ${23 * scalingFactorGMA}px;
  fill: ${({ theme }) => newGlobalTheme.colors.text.primary};
`;

export const StyledText = styled.div`
  font-weight: 700;
  font-size: ${14 * scalingFactorGMA}px;
  line-height: Line-Height/16dpi;
  letter-spacing: Letter-Spacing/-0_5;
  width: ${175 * scalingFactorGMA}px;
`;

export const GeneralAllSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 2 auto;
  padding: 0px;
  gap: ${6.78 * scalingFactorGMA}px;
  width: ${343 * scalingFactorGMA}px;
`;

export const GeneralAllSectionFirst = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: ${3.39 * scalingFactorGMA}px;
  width: ${343 * scalingFactorGMA}px;
`;

export const SubTitle = styled.div`
  gap: ${14 * scalingFactorGMA}px;
  width: ${343 * scalingFactorGMA}px;
  font-family: Speedee;
  font-weight: 400;
  font-size: ${16 * scalingFactorGMA}px;
  line-height: ${17 * scalingFactorGMA}px;
  letter-spacing: -0.13px;
`;

export const ViewEligibleItem = styled.a`
  font-weight: 400;
  font-size: ${11.87 * scalingFactorGMA}px;
  line-height: ${13.57 * scalingFactorGMA}px;
  letter-spacing: -0.13px;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-offset: Auto;
  text-decoration-thickness: Auto;
  color: #1D6CA9;
  cursor: default;
`;

export const GeneralAllSectionSecond = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: ${3.39 * scalingFactorGMA}px;
  width: ${343 * scalingFactorGMA}px;
`;

export const Expires = styled.div`
  font-weight: 400;
  font-size: ${10 * scalingFactorGMA}px;
  line-height: ${10.18 * scalingFactorGMA}px;
  letter-spacing: -0.13px;
  width: ${343 * scalingFactorGMA}px;
  height: ${11 * scalingFactorGMA}px;
  color: #6F6F6F;
`;

export const TermAndRestaurant = styled.a`
  font-weight: 400;
  font-size: ${10.18 * scalingFactorGMA}px;
  line-height: ${13.57 * scalingFactorGMA}px;
  letter-spacing: -0.13px;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-offset: Auto;
  text-decoration-thickness: Auto;
  width: ${343 * scalingFactorGMA}px;
  height: ${14 * scalingFactorGMA}px;
  color: #1D6CA9;
  cursor: default;
`;

export const GeneralAllSectionThird = styled.div`
  width: ${343 * scalingFactorGMA}px;
  height: ${14 * scalingFactorGMA}px;
`;

export const GeneralImageSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 2 1 auto;
  padding: 0px;
  gap: ${8.48 * scalingFactorGMA}px;
  width: ${343 * scalingFactorGMA}px;
`;

export const Description = styled.div`
  width: ${343 * scalingFactorGMA}px;
  font-family: Speedee;
  font-weight: 400;
  font-size: ${12 * scalingFactorGMA}px;
  line-height: ${17.75 * scalingFactorGMA}px;
  letter-spacing: -0.13px;
`;
