
import { LanguageRecord } from "utils/languages";
import { ToggleContainer, ToggleButton } from "./toggleButton.style";


interface LanguageToggleProps {
    selectedLanguage: string;
    setSelectedLanguage: React.Dispatch<React.SetStateAction<string>>;
    primaryLanguage: string;
    secondaryLanguage: string;
    enabled: boolean;
  }

  const LanguageToggle: React.FC<LanguageToggleProps> = ({
    selectedLanguage,
    setSelectedLanguage,
    primaryLanguage,
    secondaryLanguage,
    enabled,
  }) => (
    <ToggleContainer>
      <ToggleButton
        active={selectedLanguage === primaryLanguage}
        onClick={() => setSelectedLanguage(primaryLanguage)}
      >
        {LanguageRecord[primaryLanguage]}
      </ToggleButton>
      <ToggleButton
        active={selectedLanguage === secondaryLanguage}
        onClick={() => setSelectedLanguage(secondaryLanguage)}
        disabled = {!enabled}
      >
        {LanguageRecord[secondaryLanguage]}
      </ToggleButton>
    </ToggleContainer>
  );

export default LanguageToggle;