import styled from '@emotion/styled';
import { scalingFactorGMA } from 'components/shared/modal/gmaViewForm/GmaViewForm.const';
export const ToggleContainer = styled.div`
  display: flex;
  border: 2px solid #ccc;
  border-radius: 25px;
  overflow: hidden;
  width: ${274 * scalingFactorGMA}px;
  height: ${52 * scalingFactorGMA}px;
`;

export const ToggleButton = styled.button<{ active: boolean, disabled?: boolean }>`
  flex: 1;
  border: none;
  cursor: pointer;
  font-weight: bold;
  background: ${({ active }) => (active ? '#FFBC0D' : 'white')};
  transition: background 0.3s ease;
  font-family: Speedee;
  font-size: ${18 * scalingFactorGMA}px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;
