import styled from '@emotion/styled';
import { NewButtonContained, NewButtonText } from 'components/shared/button';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { newTypography } from 'styles/typography';
import zIndex from 'styles/zIndex';
import { scalingFactorGMA } from './GmaViewForm.const';

export const CloseButton = styled(NewButtonText)`
  font-family: Speedee;
  font-weight: 700;
  font-size: ${18 * scalingFactorGMA}px;
  line-height: ${20 * scalingFactorGMA}px;
  letter-spacing: -0.19px;
  text-align: center;
  color: #292929;

  height: ${52 * scalingFactorGMA}px;
  border-radius: ${30 * scalingFactorGMA}px;
  gap: ${4 * scalingFactorGMA}px;
  padding-top: ${16 * scalingFactorGMA}px;
  padding-right: ${24 * scalingFactorGMA}px;
  padding-bottom: ${16 * scalingFactorGMA}px;
  padding-left: ${24 * scalingFactorGMA}px;
`;

export const StyledGoldButton = styled(NewButtonContained)`
  color: ${newGlobalTheme.colors.text.primary};
  height: ${52 * scalingFactorGMA}px;
  gap: ${4 * scalingFactorGMA}px;
  padding-top: ${16 * scalingFactorGMA}px;
  padding-right: ${24 * scalingFactorGMA}px;
  padding-bottom: ${16 * scalingFactorGMA}px;
  padding-left: ${24 * scalingFactorGMA}px;

  border-radius: ${30 * scalingFactorGMA}px;
  border: 1px solid var(--Accent-Gold, #c08b00);
  background: var(--McDonalds-Gold, #ffbc0d);
  font-size: ${18 * scalingFactorGMA}px;

  &:disabled {
    border: none;
    background: ${newGlobalTheme.colors.button.disabledSave};
    color: ${newGlobalTheme.colors.text.disabledSave};
  }

  :hover {
    border: 1px solid var(--Accent-Gold, #c08b00);
    background: var(--McDonalds-Gold, #ffbc0d);
  }
`;

export const StyledWhiteButton = styled(NewButtonContained)`
  color: ${newGlobalTheme.colors.text.primary};
  height: ${52 * scalingFactorGMA}px;
  font-size: ${18 * scalingFactorGMA}px;
  gap: ${4 * scalingFactorGMA}px;
  padding-top: ${16 * scalingFactorGMA}px;
  padding-right: ${24 * scalingFactorGMA}px;
  padding-bottom: ${16 * scalingFactorGMA}px;
  padding-left: ${24 * scalingFactorGMA}px;
  border-radius: ${30 * scalingFactorGMA}px;
  border: 1px solid ${newGlobalTheme.colors.text.disabledSave};
  background: #fff;

  :hover {
    background-color: inherit;
  }

  &:disabled {
    color: ${newGlobalTheme.colors.text.disabledSave};
    border-radius: 5px;
    border: 1px solid ${newGlobalTheme.colors.status.archived};
    background: var(--White, #fff);
  }
`;

export const GmaFormFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  width: ${680 * scalingFactorGMA}px;
  height: ${100 * scalingFactorGMA}px;
  min-width: ${540 * scalingFactorGMA}px;
  max-width: ${1080 * scalingFactorGMA}px;
  gap: ${24 * scalingFactorGMA}px;
  padding: ${24 * scalingFactorGMA}px;
  border-top: 1px solid #d6d6d6;
`;

export const GmaFormHeader = styled.header`
  display: flex;
  width: ${680 * scalingFactorGMA}px;
  height: ${102 * scalingFactorGMA}px;
  min-width: ${540 * scalingFactorGMA}px;
  max-width: ${1080 * scalingFactorGMA}px;
  justify-content: space-between;
  align-items: center; 
  padding: ${24 * scalingFactorGMA}px;
  border-bottom: 1px solid #adadad;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${680 * scalingFactorGMA}px;
  height: ${1236 * scalingFactorGMA}px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity ${newTypography.transition}s;
  z-index: ${zIndex.modal};
  background: ${newGlobalTheme.colors.global.background};
  border-radius: ${16 * scalingFactorGMA}px;
`;

export const TextContainer = styled.div`
  width: ${544 * scalingFactorGMA}px;
  height: ${54 * scalingFactorGMA}px;
  gap: ${6 * scalingFactorGMA}px;
`;

export const ModalTitle = styled.div`
  font-family: Speedee;
  font-weight: 700;
  font-size: ${32 * scalingFactorGMA}px;
  line-height: ${32 * scalingFactorGMA}px;
  letter-spacing: -0.15px;
  padding-bottom: 2px;
`;

export const ModalSubTitle = styled.div`
  font-family: Speedee;
  font-weight: 400;
  font-size: ${14 * scalingFactorGMA}px;
  line-height: ${16 * scalingFactorGMA}px;
  letter-spacing: -0.15px;
  color: #6f6f6f;
`;

export const StyledIcon = styled.div`
  width: ${32 * scalingFactorGMA}px;
  height: ${32 * scalingFactorGMA}px;
  gap: ${36 * scalingFactorGMA}px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;
